<template>
  <div class="article-container">
    <el-card class="filter-card">
      <el-form ref="form" label-width="80px" size="mini">
        <div class="flexparents">
          <div class="flexparents rightgap">
            {{ $t("sl_time.text") }}
            <el-date-picker
              value-format="yyyy-MM-dd 00:00:00"
              v-model="form.createTimeAfter"
              type="date"
              :placeholder="this.$t('sl_time.begin')"
            >
            </el-date-picker>

            <el-date-picker
              value-format="yyyy-MM-dd 23:59:59"
              v-model="form.creatTimeBefore"
              type="date"
              :placeholder="this.$t('sl_time.over')"
            >
            </el-date-picker>

            {{ $t("processes") }}
            <el-select
              clearable
              v-model="form.processes"
              :placeholder="this.$t('checkText')"
            >
              <el-option
                v-for="item in processesoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-button type="primary" :disabled="loading" @click="cleardata()">
              {{ $t("bt_clear") }}</el-button
            >
            <el-button
              type="primary"
              :disabled="loading"
              @click="loadArticles(1)"
              >{{ $t("bt_search") }}</el-button
            >

            <el-button
              type="info"
              :disabled="loading"
              @click="ifgaoji = !ifgaoji"
              >{{
                ifgaoji ? $t("bt_retrac") : $t("bt_senior_check")
              }}</el-button
            >
          </div>
          <div v-if="ifgaoji" class="flexparents rightgap zdhh">
            <div>
              {{ $t("workOrderNo") }}
              <el-input
                class="input rightgap"
                v-model="form.workOrderNo"
                :placeholder="this.$t('inputText')"
              ></el-input>
            </div>
            <div>
              {{ $t("createdName") }}
              <el-input
                class="input rightgap"
                v-model="form.createdName"
                :placeholder="this.$t('inputText')"
              ></el-input>
            </div>
            <div>
              {{ $t("customerName") }}
              <el-input
                class="input rightgap"
                v-model="form.customerName"
                :placeholder="this.$t('inputText')"
              ></el-input>
            </div>
            <div>
              {{ $t("personLiable") }}
              <el-input
                class="input rightgap"
                v-model="form.personLiable"
                :placeholder="this.$t('inputText')"
              ></el-input>
            </div>

            <div>
              {{ $t("faeOperatorName") }}
              <el-input
                class="input rightgap"
                v-model="form.faeOperatorName"
                :placeholder="this.$t('inputText')"
              ></el-input>
            </div>
            <div>
              {{ $t("salesman") }}
              <el-input
                class="input rightgap"
                v-model="form.salesman"
                :placeholder="this.$t('inputText')"
              ></el-input>
            </div>
            <div>
              {{ $t("platform") }}
              <el-select
                class="flexparents rightgap"
                v-model="form.platform"
                :placeholder="this.$t('checkText')"
              >
                <el-option
                  v-for="item in pingtaioptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              {{ $t("stations") }}
              <el-select
                class="flexparents rightgap"
                v-model="form.stations"
                :placeholder="this.$t('checkText')"
              >
                <el-option
                  v-for="item in stationsoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              {{ $t("type") }}
              <el-select
                class="flexparents rightgap"
                v-model="form.type"
                :placeholder="this.$t('checkText')"
              >
                <el-option
                  v-for="item in problemtypeaoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="parseInt(item.value)"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              {{ $t("urgentType") }}
              <el-select
                class="flexparents rightgap"
                v-model="form.urgentType"
                :placeholder="this.$t('checkText')"
                clearable
              >
                <el-option
                  v-for="item in urgentTypeoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              {{ $t("transformFlag") }}
              <el-select
                class="flexparents rightgap"
                v-model="form.transformFlag"
                clearable
                :placeholder="this.$t('checkText')"
              >
                <el-option
                  v-for="item in transformFlagoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              {{ $t("description") }}
              <el-input
                class="input"
                v-model="form.description"
                :placeholder="this.$t('inputText')"
              ></el-input>
            </div>
            <div>
              {{ $t("productModelType") }}
              <el-select
                multiple
                v-model="form.productModelType"
                filterable
                :placeholder="this.$t('checkText')"
              >
                <el-option
                  v-for="item in productModelTypeOptions"
                  :key="item.name"
                  :label="item.label"
                  :value="item.name"
                >
                </el-option>
              </el-select>

              <el-input
                class="flexparents rightgap"
                style="width: 120px"
                v-if="ifshoutian"
                v-model="shoutianjixing"
                :placeholder="this.$t('inputText')"
              ></el-input>
            </div>
          </div>
        </div>
      </el-form>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        {{ $t("totalCount_text_TOP") }} {{ totalCount }}
        {{ $t("totalCount_text_over") }}
      </div>

      <el-table
        ref="el_table"
        :data="articles"
        :height="tableHeight"
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
        :row-class-name="tableRowClassName"
      >
        <el-table-column :label="this.$t('process')" min-width="160">
          <template slot-scope="scope">
            <el-tag :type="processStatus[scope.row.process].type">{{
              processStatus[scope.row.process].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNo"
          min-width="160"
          :label="this.$t('workOrderNo')"
          key="orderNo"
        >
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="productModelType"
          :label="this.$t('productModelType')"
          key="productModelType"
        ></el-table-column>

        <el-table-column
          min-width="160"
          prop="completedByName"
          :label="this.$t('completedByName')"
          key="completedByName"
        >
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="createdTime"
          :label="this.$t('createdTime')"
          key="createdTime"
        >
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="station"
          :label="this.$t('station')"
          key="station"
        >
          <template slot-scope="scope">
            <el-tag :type="progressStatus[scope.row.station].type">{{
              progressStatus[scope.row.station].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          min-width="200"
          prop="customerName"
          :label="this.$t('customerName')"
          key="customerName"
        ></el-table-column>

        <el-table-column
          min-width="160"
          prop="type"
          :label="this.$t('type')"
          key="type"
        >
          <template v-slot="scope">
            {{
              problemtypeaoptions[scope.row.type] &&
              problemtypeaoptions[scope.row.type].label
            }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="160"
          :label="this.$t('platform')"
          key="platform"
        >
          <template slot-scope="scope">
            {{
              pingtaioptions[scope.row.platform] &&
              pingtaioptions[scope.row.platform].label
            }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="160"
          prop="personLiable"
          :label="this.$t('personLiable')"
          key="personLiable"
        ></el-table-column>

        <el-table-column
          min-width="160"
          prop="faeOperatorName"
          :label="this.$t('faeOperatorName')"
          key="faeOperatorName"
        ></el-table-column>

        <el-table-column
          prop="keyword"
          :label="this.$t('keyword')"
          key="keyword"
        ></el-table-column>
        <el-table-column
          prop="description"
          width="300"
          :label="this.$t('description')"
          key="description"
        ></el-table-column>
        <el-table-column
          min-width="160"
          :label="this.$t('urgentType')"
          key="urgentType"
        >
          <template slot-scope="scope">
            <el-tag :type="urgentStatus[scope.row.urgentType].type">{{
              urgentStatus[scope.row.urgentType].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          key="remark"
          prop="remark"
          width="250"
          :label="this.$t('remark')"
        ></el-table-column>

        <el-table-column
          key="customerDescription"
          prop="customerDescription"
          width="250"
          :label="this.$t('customerDescription')"
        ></el-table-column>

        <el-table-column  :width="OperateWidth" :label="$t('operate')" fixed="right">
         <template slot-scope="scope">
            <div>
              <el-tooltip
                :content="$t('lookAndDo')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="showdetaile(scope.row)"
                  circle
                  icon="el-icon-search"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                class="chongqi"
                v-if="scope.row.process === 1 "
                v-show="cando"
                :content="$t('chongqi')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="reset(scope.row)"
                  circle
                  icon="el-icon-success"
                  type="primary"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                v-if="scope.row.process === 0 || scope.row.process === 5"
                v-show="cando"
                :content="$t('wancheng')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="finish(scope.row)"
                  circle
                  icon="el-icon-close"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                v-show="
                  (form.ownerType === '4' || form.ownerType === '0') &&
                  cando === true && scope.row.process !== 1
                "
                :content="$t('baingengfae')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="giveFae(scope.row)"
                  circle
                  icon="el-icon-user"
                  type="primary"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                v-show="form.ownerType === '0' && scope.row.process !== 1"
                :content="$t('huisuyanfa')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="refresh(scope.row)"
                  circle
                  icon="el-icon-refresh-left"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                v-show="cando === true && scope.row.process !== 1"
                :content="$t('xiugaijichu')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="changeorder(scope.row)"
                  circle
                  icon="el-icon-edit-outline"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                v-show="form.ownerType === '0'"
                :content="$t('chakanshijian')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="showdtimeline(scope.row)"
                  circle
                  icon="el-icon-time"
                  type="primary"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                v-show="cando === true"
                style="color: white; background: red; border-color: red"
                :content="$t('shanchu')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="delect(scope.row)"
                  circle
                  icon="el-icon-delete"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- /数据列表 -->

      <!-- 列表分页 -->
      <!--
        total 用来设定总数据的条数
        它默认按照 10 条每页计算总页码
        page-size 每页显示条目个数，支持 .sync 修饰符，默认每页 10 条

        90 3 90 / 3 = 30
       -->
      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      >
      </el-pagination>

      <!-- /列表分页 -->
    </el-card>

    <el-dialog
      :title="$t('shifoushanchu')"
      width="450px"
      :visible.sync="deletedvis"
      append-to-body
    >
      <div>{{ this.delectform.orderNo }}</div>
      <el-button type="danger" @click="deletedOrder" plain>{{
        $t("quedingshanchu")
      }}</el-button>
      <el-button type="success" @click="deletedvis = false">{{
        $t("zanbushanchu")
      }}</el-button>
    </el-dialog>

    <el-dialog
      :title="$t('shifouhuisu')"
      width="450px"
      :visible.sync="refreshvis"
      append-to-body
    >
      <el-button type="danger" @click="refreshOrder" plain>{{
        $t("quedinghuisu")
      }}</el-button>
      <el-button type="success" @click="refreshvis = false">{{
        $t("zanbuhuisu")
      }}</el-button>
    </el-dialog>

    <el-dialog
      :title="$t('xiugaijichu')"
      width="550px"
      :visible.sync="changewindowsshow"
      append-to-body
    >
      <el-form ref="changeform" :model="changeform" label-width="120px">
        <el-form-item :label="$t('area') + '*'">
          <el-select v-model="changeform.area" :placeholder="$t('checkText')">
            <el-option
              v-for="item in areaoptions"
              :key="item.value"
              :label="item.label"
              :value="parseInt(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('khgn')">
          <el-input v-model="changeform.customerName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('salesman') + '*'">
          <el-input v-model="changeform.salesman"></el-input>
        </el-form-item>
        <el-form-item :label="$t('type') + '*'">
          <el-select v-model="changeform.type" :placeholder="$t('checkText')">
            <el-option
              v-for="item in problemtypeaoptions"
              :key="item.value"
              :label="item.label"
              :value="parseInt(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('platform')">
          <el-select
            class="short"
            v-model="changeform.platform"
            :placeholder="$t('checkText')"
          >
            <el-option
              v-for="item in pingtaioptions"
              :key="item.value"
              :label="item.label"
              :value="parseInt(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('productModelType')">
          <el-input v-model="changeform.productModelType"></el-input>
        </el-form-item>
        <el-form-item :label="$t('productType')">
          <el-select
            v-model="changeform.productType"
            :placeholder="$t('checkText')"
          >
            <el-option
              v-for="item in prodectoptions"
              :key="item.value"
              :label="item.label"
              :value="parseInt(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('networkType')">
          <el-select
            v-model="changeform.networkType"
            :placeholder="$t('checkText')"
          >
            <el-option
              v-for="item in netoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('personLiable')">
          <el-input v-model="changeform.personLiable"></el-input>
        </el-form-item>

        <el-form-item :label="$t('keyword')">
          <el-input v-model="changeform.keyword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('description') + '*'">
          <el-input type="textarea" v-model="changeform.description"></el-input>
        </el-form-item>

        <el-form-item :label="$t('urgentType')">
          <el-radio-group v-model="changeform.urgentType">
            <el-radio :label="0">{{ $t("di") }}</el-radio>
            <el-radio :label="1">{{ $t("zhong") }}</el-radio>
            <el-radio :label="2">{{ $t("gao") }}</el-radio>
            <el-radio :label="3">{{ $t("jigao") }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('tupian')">
          <el-upload
            action="/api/procedure/technical-support-work-order/uploadImg"
            :headers="uploadHeaders"
            accept=".jpg,.png"
            :limit="5"
            multiple
            list-type="picture"
            :file-list="changeform.image"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleImageRemove"
            :on-success="handleImgUploadSuccess"
          >
            <el-button size="mini" type="primary">{{
              $t("tupiantixing")
            }}</el-button>
          </el-upload>
        </el-form-item>

        <el-form-item :label="$t('wenjian')">
          <el-upload
            action="/api/procedure/technical-support-work-order/uploadFile"
            :headers="uploadHeaders"
            :limit="5"
            :file-list="changeform.attachment"
            :on-remove="handleRemove"
            :on-success="handleUploadSuccess"
          >
            <el-button size="mini" type="primary">{{
              $t("dianjishangchuan")
            }}</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changeorderhttp">{{
            $t("quedingxiugai")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="$t('shijianxianliebiao')"
      width="550px"
      :visible.sync="timelineViewShow"
      append-to-body
    >
      <div class="timelineparents">
        <div>
          {{ $t("gongdanliucheng") }}
          <el-timeline reverse="true">
            <el-timeline-item
              v-for="(activity, index) in timelinedata"
              :key="index"
              :timestamp="activity.operateTime"
            >
              {{ activity.operateDescription }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('clxq')"
      :visible.sync="showkf"
      @closed="loadArticles"
      width="1400px"
    >
      <el-dialog
        :close-on-click-modal="false"
        :title="$t('gxzt')"
        width="550px"
        :visible.sync="planVisable"
        append-to-body
      >
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item :label="$t('sjxx')">
            <div>{{ $t("zzsj") }} {{ solvform.transformTime }}</div>
            <div>{{ $t("zzsj") }} {{ solvform.completedTime }}</div>
          </el-form-item>

          <el-form-item :label="$t('stuckPoint')">
            <el-select
              v-model="solvform.stuckPoint"
              :placeholder="$t('checkText')"
            >
              <el-option
                v-for="item in difoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('type')">
            <el-select
              v-model="solvform.type"
              :placeholder="$t('checkText')"
              class="rightgap"
            >
              <el-option
                v-for="item in problemtypeaoptions"
                :key="item.value"
                :label="item.label"
                :value="parseInt(item.value)"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('solution')">
            <el-input type="textarea" v-model="solvform.solution"></el-input>
          </el-form-item>
          <el-form-item :label="$t('personLiable')">
            <el-input v-model="solvform.personLiable"></el-input>
          </el-form-item>
          <el-form-item :label="$t('remark')">
            <el-input type="textarea" v-model="solvform.remark"></el-input>
          </el-form-item>
        </el-form>

        <el-button type="success" plain @click="solveOrder">
          {{ $t("quedingxiugai") }}
        </el-button>
      </el-dialog>

      <el-dialog
        :title="$t('zygz')"
        :close-on-click-modal="false"
        append-to-body
        :visible.sync="showtranslate"
      >
        <el-cascader
          style="width: 100%"
          id="'edit'"
          v-model="translateTO"
          :options="treedata"
          :placeholder="$t('checkText')"
          :props="{ checkStrictly: true, label: 'name', value: 'deptId' }"
          clearable
          @change="handleSelectDept"
        />

        <div slot="footer" class="dialog-footer">
          <el-button @click="showtranslate = false">
            {{ $t("quxiao") }}
          </el-button>
          <el-button
            :loading="translating"
            :disabled="translating"
            type="primary"
            @click="translate"
            >{{ $t("queding") }}</el-button
          >
        </div>
      </el-dialog>

      <span slot="title" class="dialog-footer zdhh">
        <div class="leftgap" v-show="showkswt">
          <el-radio v-model="complaintFlag" label="1">{{ $t("shi") }}</el-radio>
          <el-radio v-model="complaintFlag" label="0">{{ $t("fou") }}</el-radio>

          <el-button type="primary" @click="sendtoComplaint">
            {{ $t("queding") }}</el-button
          >
        </div>
      </span>

      <!-- 11111111 -->

      <el-steps
        :active="steptype"
        finish-status="success"
        class="gap"
        style="padding-left: 16px; padding-right: 16px"
      >
        <el-step
          :title="$t('jindu1')"
          :description="FAEdatile.createdTime"
        ></el-step>
        <el-step
          :title="$t('jindu2')"
          :description="FAEdatile.firstBrowseTime"
        ></el-step>
        <el-step
          :title="$t('jindu3')"
          :description="yanfadatile.createdTime"
        ></el-step>
        <el-step
          :title="$t('jindu4')"
          :description="yanfadatile.firstBrowseTime"
        ></el-step>
        <el-step
          :title="$t('jindu5')"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step
          :title="$t('jindu6')"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step
          :title="$t('jindu7')"
          :description="FAEdatile.completedTime"
        ></el-step>
      </el-steps>
      <div class="destitle">{{ $t("jibenxinxi") }}</div>
      <el-descriptions class="desbasebody" :column="3">
        <el-descriptions-item :label="$t('workOrderNo')">{{
          FAEdatile.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('productModelType')">{{
          FAEdatile.productModelType
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('area')">{{
          areaoptions[FAEdatile.area] && areaoptions[FAEdatile.area].label
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('type')">{{
          problemtypeaoptions[FAEdatile.type] &&
          problemtypeaoptions[FAEdatile.type].label
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('platform')">{{
          pingtaioptions[FAEdatile.platform] &&
          pingtaioptions[FAEdatile.platform].label
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('urgentType')">
          {{ urgentStatustext[FAEdatile.urgentType] }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('faeOperatorName')">{{
          FAEdatile.faeOperatorName
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('personLiable')">{{
          FAEdatile.personLiable
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('createdName')">{{
          FAEdatile.createdName
        }}</el-descriptions-item>

        <el-descriptions-item :label="$t('complaintFlag')">{{
          complaintoptions[FAEdatile.complaintFlag] &&
          complaintoptions[FAEdatile.complaintFlag].label
        }}</el-descriptions-item>
      </el-descriptions>

      <div class="destitle" v-if="this.esservedatiledata.orderNo != null">
        {{ $t("dianshangqiangxing") }}
      </div>

      <div class="desbody" v-if="this.esservedatiledata.orderNo != null">
        <el-descriptions :column="4">
          <el-descriptions-item label="IMEI">{{
            esservedatiledata.imei
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('platform')">{{
            esservedatiledata.platform
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('deviceStatus')">{{
            esservedatiledata.deviceStatus
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('createdTime')">{{
            esservedatiledata.createdTime
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('channel')">{{
            esservedatiledata.channel
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('country')">{{
            esservedatiledata.country
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('otherContactInfo')">{{
            esservedatiledata.otherContactInfo
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('ledStatus')">{{
            esservedatiledata.ledStatus
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('simCardOperator')">{{
            esservedatiledata.simCardOperator
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('usageScenario')">{{
            esservedatiledata.usageScenario
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('installedVehicleModel')">{{
            esservedatiledata.installedVehicleModel
          }}</el-descriptions-item>
          <el-descriptions-item label="Email">{{
            esservedatiledata.email
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item :label="$t('description')" :span="3">
            <p style="white-space: pre-line">
              {{ esservedatiledata.description }}
            </p>
          </el-descriptions-item>

          <el-descriptions-item :label="$t('tupian')" :span="16">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              v-for="{ url } in esservedatiledata.image"
              :key="url"
              :src="url"
              :previewSrcList="ESpreviewList"
            />
          </el-descriptions-item>
          <el-descriptions-item :label="$t('wenjian')" :span="16">
            <p v-for="{ url, path } in esservedatiledata.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle" v-if="this.kfdatile.orderNo != null">
        {{ $t("kfxiangqing") }}
      </div>

      <div class="desbody" v-if="this.kfdatile.orderNo != null">
        <el-descriptions :column="3">
          <el-descriptions-item :label="$t('createdName')">{{
            kfdatile.createdName
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('customerName')">{{
            kfdatile.customerName
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('phoneNumber')">{{
            kfdatile.phoneNumber
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('title')">{{
            kfdatile.title
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('reason')">{{
            kfdatile.reason
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('createdTime')">{{
            kfdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item :label="$t('description')" :span="3">
            <p style="white-space: pre-line">
              {{ kfdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('remark')" :span="3">{{
            kfdatile.remark
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('tupian')" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in kfdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="KFpreviewList"
            />
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle">{{ $t("faexq") }}</div>
      <div class="desbody">
        <el-descriptions :column="3">
          <el-descriptions-item :label="$t('keyword')">{{
            FAEdatile.keyword
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('stuckPoint')">{{
            difoptions[FAEdatile.stuckPoint] &&
            difoptions[FAEdatile.stuckPoint].label
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('createdTime')">{{
            FAEdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item :label="$t('wentichulifangan')" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in solutionlinedata"
                :key="index"
                :timestamp="activity.time"
              >
                <p style="white-space: pre-line">
                  {{ activity.detail }}
                </p>
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('description')" :span="3">
            <p style="white-space: pre-line">
              {{ FAEdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('customerDescription')" :span="3">{{
            FAEdatile.customerDescription
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('tupian')" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in FAEdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="FAEpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item :label="$t('fujian')" :span="3">
            <p v-for="{ url, path } in FAEdatile.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle" v-if="this.yanfadatile.orderNo != null">
        {{ $t("yanfaxiangqing") }}
      </div>

      <div class="desbody" v-if="this.yanfadatile.orderNo != null">
        <el-descriptions :column="3">
          <el-descriptions-item :label="$t('createdTime')">{{
            yanfadatile.createdTime
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('zhuangtai')">{{
            attributeoptions[yanfadatile.attribute] &&
            attributeoptions[yanfadatile.attribute].label
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('personLiable')">{{
            yanfadatile.personLiable
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item :label="$t('jieshishuoming')" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in remakelinedate"
                :key="index"
                :timestamp="activity.time"
              >
                {{ activity.detail }}
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>

          <el-descriptions-item :label="$t('tapdlianjie')" :span="3">{{
            yanfadatile.tapdLink
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('yfkd')" :span="3">{{
            yanfadatile.developStuckPoint
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('jjfangan')" :span="3">{{
            yanfadatile.solution
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('tupian')" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in yanfadatile.imageInfos"
              :key="url"
              :src="url"
              :previewSrcList="YFpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item :label="$t('fujian')" :span="3">
            <p v-for="{ url, path } in yanfadatile.fileInfos" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>

    <el-dialog
      modal-append-to-body
      :modal="false"
      :visible.sync="ifshowshaixuan"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <el-dialog :title="$t('fenpeifae')" :visible.sync="showAssignFaeOperator">
      <el-select
        v-model="setFaeManForm.faeOperatorName"
        :placeholder="this.$t('checkText')"
      >
        <el-option
          v-for="item in FAElist"
          :key="item.userId"
          :label="item.name"
          :value="item.name"
          @change="changeoption"
        >
        </el-option>
      </el-select>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAssignFaeOperator = false">{{
          $t("quxiao")
        }}</el-button>
        <el-button type="primary" @click="RunFaeOperator">{{
          $t("queding")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="ifshowshaixuan">
      <el-checkbox-group v-model="GLFAEcheckList">
        <el-checkbox label="completedByName">{{
          $t("completedByName")
        }}</el-checkbox>
        <el-checkbox label="station">{{ $t("station") }}</el-checkbox>
        <el-checkbox label="area">{{ $t("area") }}</el-checkbox>
        <el-checkbox label="customerName">{{ $t("customerName") }}</el-checkbox>
        <el-checkbox label="salesman">{{ $t("salesman") }}</el-checkbox>
        <el-checkbox label="type">{{ $t("type") }}</el-checkbox>
        <el-checkbox label="platform">{{ $t("platform") }}</el-checkbox>
        <el-checkbox label="personLiable">{{ $t("personLiable") }}</el-checkbox>
        <el-checkbox label="productModelType">{{
          $t("productModelType")
        }}</el-checkbox>
        <el-checkbox label="faeOperatorName">{{
          $t("faeOperatorName")
        }}</el-checkbox>
        <el-checkbox label="keyword">{{ $t("keyword") }}</el-checkbox>
        <el-checkbox label="description">{{ $t("description") }}</el-checkbox>
        <el-checkbox label="urgentType">{{ $t("urgentType") }}</el-checkbox>
        <!-- <el-checkbox label="feedbackTimes">反馈次数</el-checkbox> -->
        <el-checkbox label="remark">{{ $t("remark") }}</el-checkbox>
        <el-checkbox label="customerDescription">{{
          $t("customerDescription")
        }}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifshowshaixuan = false">
          {{ $t("jincishengxiao") }}</el-button
        >
        <el-button type="primary" @click="savashaixuan()">
          {{ $t("baocunshezhe") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  SearchFaeOrderUrl,
  FaeSolveOrderUrl,
  FaeFinish,
  FaeDelect,
  FaeRefreshOrder,
  EditaeOrderUrl,
  FaeResetOrderUrl,
  transformUrl,
  getOneFaeUrl,
  gettimeline,
  FaeWithdraw,
  getSalesListUrl,
  getFaeOperatorListUrl,
  transformFaeOperatorUrl,
  noteComplaintFlag,
} from "@/api/fae";
import { gettransformdeptlisturl } from "@/api/jurisdiction";
import { translateDataToTree } from "@/utils/setMethods";
import {
  productModelTypeOptions,
  areaoptions,
  problemtypeaoptions,
  prodectoptions,
  netoptions,
  pingtaioptions,
  difoptions,
  attributeoptions,
  complaintoptions,
} from "@/options/index";
import { suanfa } from "@/utils/index";
export default {
  name: "ArticleIndex",
  components: {},
  props: {},
  data() {
    return {
      GLFAEcheckList: [
        "completedByName",
        "station",
        "area",
        "customerName",
        "salesman",
        "type",
        "platform",
        "personLiable",
        "productModelType",
        "faeOperatorName",
        "keyword",
        "description",
        "urgentType",
        "feedbackTimes",
        "remark",
        "customerDescription",
      ],
      showkswt: false,
      complaintFlag: "1",
      ifshowshaixuan: false,
      tableHeight: window.innerHeight - 320,
      ifgaoji: false,
      timelinedata: [],
      solutionlinedata: [],
      remakelinedate: [],
      treedata: [],
      progressStatus: [
        { text: this.$t("geren"), type: "warning" }, // 0
        { text: this.$t("kefu"), type: "success" }, // 1
        { text: "FAE", type: "primary" }, // 2
        { text: this.$t("yanfa"), type: "danger" }, // 3
        { text: this.$t("xiaochengxu"), type: "success" }, // 3
        { text: this.$t("dianshang"), type: "success" }, // 3
      ],

      Language: null,

      refreshvis: false,
      ifshoutian: false,
      shoutianjixing: "",
      translateTO: 0,
      showtranslate: false,
      translateId: "",
      translating: false,
      showkf: false,
      changepsdform: {
        oldPassword: "",
        newPassword: "",
      },
      productModelType: productModelTypeOptions[0].name,
      productModelTypeOptions,
      areaoptions,
      problemtypeaoptions,
      prodectoptions,
      netoptions,
      pingtaioptions,
      difoptions,
      attributeoptions,
      complaintoptions,
      stationsoption: [
        {
          value: null,
          label: this.$t("quanbu"),
        },
        {
          value: "1",
          label: this.$t("kefu"),
        },
        {
          value: "2",
          label: "FAE",
        },
        {
          value: "3",
          label: this.$t("yanfa"),
        },
      ],
      urgentTypeoption: [
        {
          value: null,
          label: this.$t("quanbu"),
        },
        {
          value: "0",
          label: this.$t("di"),
        },
        {
          value: "1",
          label: this.$t("zhong"),
        },
        {
          value: "2",
          label: this.$t("gao"),
        },
        {
          value: "3",
          label: this.$t("jigao"),
        },
      ],
      fromoption: [
        {
          value: null,
          label: this.$t("quanbu"),
        },
        {
          value: "0",
          label: this.$t("zijian"),
        },
        {
          value: "1",
          label: this.$t("kefu"),
        },
        {
          value: "2",
          label: this.$t("yewu"),
        },
      ],
      processesoptions: [
        {
          value: null,
          label: this.$t("buxian"),
        },
        {
          value: "0",
          label: this.$t("yihcuangjian"),
        },
        {
          value: "1",
          label: this.$t("yiwancheng"),
        },
        {
          value: "0,2,3,5",
          label: this.$t("weiwancheng"),
        },
        {
          value: "3",
          label: this.$t("yizhuanchu"),
        },
        {
          value: "5",
          label: this.$t("zhuanchuhou"),
        },
      ],
      saleslist: [],
      FAElist: [],
      timelineViewShow: false,
      showchangepsw: false,
      complaterform: { workOrderId: null },
      delectform: { workOrderId: null },
      fileList: [],
      textarea: "",
      ComplectVisible: false,
      AddVisible: false,
      planVisable: false,
      deletedvis: false,
      dataildata: "",
      multfilehead: {},
      multfiledata: {},
      transformFlagoption: [
        {
          value: null,
          label: this.$t("quanbu"),
        },
        {
          value: "1",
          label: this.$t("shi"),
        },
        {
          value: "0",
          label: this.$t("fou"),
        },
      ],

      value: "",
      articles: [], // 文章数据列表
      urgentStatus: [
        { status: 0, text: this.$t("di"), type: "info" }, // 1
        { status: 1, text: this.$t("zhong"), type: "primary" }, // 1
        { status: 2, text: this.$t("gao"), type: "warning" }, // 1
        { status: 3, text: this.$t("jigao"), type: "danger" }, // 1
      ],
      urgentStatustext: [
        this.$t("di"), // 1
        this.$t("zhong"), // 1
        this.$t("gao"), // 1
        this.$t("jigao"), // 1
      ],
      processStatus: [
        { status: 0, text: this.$t("kaiqi"), type: "primary" }, // 1
        { status: 1, text: this.$t("wancheng"), type: "success" }, // 1
        { status: 2, text: this.$t("daipingjia"), type: "info" }, // 1
        { status: 3, text: this.$t("yizhuanchu"), type: "warning" }, // 1
        { status: 4, text: this.$t("yiwancheng"), type: "warning" }, // 1
        { status: 5, text: this.$t("daiwancheng"), type: "info" }, // 1
      ],

      totalCount: 0, // 总数据条数
      status: null, // 查询文章的状态，不传就是全部
      channels: [], // 文章频道列表
      channelId: null, // 查询文章的频道
      rangeDate: null, // 筛选的范围日期
      loading: true, // 表单数据加载中 loading

      form: {
        workOrderNo: null,
        currentUserFlag: "true", // 当前员工 or 全部员工
        creatTimeBefore: null,
        createTimeAfter: null,
        sourceType: null,
        selfFlag: null,
        size: 20, // 每页大小
        page: 1, // 当前页码
        ownerType: "4", // 6 国际客户
        stations: null, // 当前站点
      },
      deptId: "",

      solvform: {
        type: 0,
        workOrderId: "",
        remark: "",
        solution: "",
        stuckPoint: "",
        personLiable: "",
        image: [],
        attachment: [],
      },
      changeform: {
        urgentType: "",
        workOrderId: "",
        area: "",
        customerName: "",
        personLiable: "",
        description: "",
        keyword: "",
        networkType: "",
        platform: "",
        productModelType: "",
        faeOperatorName: "",
        productType: "",
        salesman: "",
        type: "",
        image: [],
        attachment: [],
      },
      changewindowsshow: false,
      kfform: {
        workOrderId: null,
      },
      esservedatiledata: {},
      FAEdatile: {},
      kfdatile: {},
      yanfadatile: {},
      detail_kefu: false,
      dialogImageUrl: "",
      dialogVisible: false,
      FAEpreviewList: [],
      YFpreviewList: [],
      KFpreviewList: [],
      ESpreviewList: [],
      withdrawing: false,
      showAssignFaeOperator: false,
      setFaeManForm: {
        faeOperator: null,
        faeOperatorName: null,
        workOrderId: null,
      },
      cando: true,
      OperateWidth: null,
      steptype: 0,
    };
  },
  computed: {
    // 用户权限
    perms() {
      try {
        return JSON.parse(localStorage.getItem("userdata")).permInfos.map(
          ({ perms }) => perms
        );
      } catch (e) {
        return [];
      }
    },

    uploadHeaders() {
      const usertoken = JSON.parse(window.localStorage.getItem("usertoken"));
      return { Authorization: usertoken };
    },
  },
  watch: {
    articles(_new) {
      this.$refs.el_table.doLayout();
    },

    "form.productModelType"(_new) {
      const { productType, networkType } =
        this.productModelTypeOptions.find(({ name }) => name === _new) ?? {};

      this.form.productType = productType;
      this.form.networkType = networkType;

      if (_new == this.$t("zidingyi")) {
        this.ifshoutian = true;
      } else {
        this.ifshoutian = false;
      }
    },
  },
  created() {
    this.form.workOrderNo = this.$route.query.orderNo;

    this.getsaleslist();
    this.getFaeOperatorList();

    window.addEventListener("resize", this.getTableHeight);

    this.loadArticles(1);

    if (this.perms.includes("client-customer-work-order:manage") === false) {
      this.cando = false;
      this.OperateWidth = 80;
    } else {
      this.OperateWidth = 240;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.getTableHeight);
  },
  mounted() {
    this.form.selfFlag = null;
    this.form.sourceType = null;
    this.getdeptlist();
    let token = JSON.parse(window.localStorage.getItem("user"));
    this.multfilehead = { Authorization: token };

    let FAEcheckList = JSON.parse(
      window.localStorage.getItem("GLFAEcheckList")
    );
    if (FAEcheckList) {
      this.GLFAEcheckList = FAEcheckList;
    }
    this.Language = this.$i18n.locale;
  },
  methods: {
    async sendtoComplaint() {
      try {
        const res = await noteComplaintFlag({
          complaintFlag: this.complaintFlag,
          workOrderId: this.translateId,
        });
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("gxcg"),
            type: "success",
          });
          this.FAEdatile.complaintFlag = this.complaintFlag;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showkswt = false;
      }
    },
    LanguageChange(value) {
      this.$i18n.locale = value;
      localStorage.setItem("change-language", value);
      location.reload();
    },
    async RunFaeOperator() {
      try {
        const res = await transformFaeOperatorUrl(this.setFaeManForm);
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("fpfaecg"),
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showAssignFaeOperator = false;
        this.loadArticles();
      }
    },

    async getsaleslist() {
      try {
        const res = await getSalesListUrl();
        if (res.data.code === 200) {
          this.saleslist = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },

    changeoption(item) {
      this.setFaeManForm.faeOperator = item.userId;
    },

    async getFaeOperatorList() {
      try {
        const res = await getFaeOperatorListUrl();
        if (res.data.code === 200) {
          this.FAElist = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },

    savashaixuan() {
      window.localStorage.setItem(
        "GLFAEcheckList",
        JSON.stringify(this.GLFAEcheckList)
      );
      this.ifshowshaixuan = false;
    },
    showshaixuan() {
      this.ifshowshaixuan = true;
    },

    cleardata() {
      (this.form = {
        currentUserFlag: "true", // 当前员工 or 全部员工
        creatTimeBefore: null,
        createTimeAfter: null,
        sourceType: null,
        selfFlag: null,
        size: 20, // 每页大小
        page: 1, // 当前页码
        ownerType: "4", // 
        stations: null, // 当前站点
      }),
        this.loadArticles();
    },
    getTableHeight() {
      this.tableHeight = window.innerHeight - 320;
    },

    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },

    tableRowClassName({ row, rowIndex }) {
      if (row.process == 1) {
        return "complate-row";
      } else if (
        (row.faeNotifiedFlag == true && this.form.ownerType == 0) ||
        (row.businessNotifiedFlag == true && this.form.ownerType == 1)
      ) {
        return "warning-row";
      }

      return "white-row";
    },

    async withdraw() {
      if (this.withdrawing) return;
      this.withdrawing = true;
      try {
        const res = await FaeWithdraw({ workOrderId: this.translateId });
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("cggychfae"),
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
        this.withdrawing = false;
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
        this.withdrawing = false;
      }
    },
    handleSelectDept(val) {
      if (val) {
        this.deptId = val[val.length - 1];
      }
    },
    async getdeptlist() {
      try {
        const res = await gettransformdeptlisturl();
        if (res.data.code === 200) {
          this.treedata = translateDataToTree(res.data.data);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
    async getdetaile(id) {
      this.showkswt = false;
      this.complaintFlag = "1";
      // 初始化详情数据
      this.kfdatile = {};
      this.yanfadatile = {};
      this.FAEdatile = {};
      this.esservedatiledata = {};
      try {
        const res = await getOneFaeUrl({
          workOrderId: id,
          ownerType: this.form.ownerType,
        });
        if (res.data.code === 200) {
          if (res.data.data.ecServeClientWorkOrderVo) {
            this.esservedatiledata = {
              ...res.data.data.ecServeClientWorkOrderVo,
              attachment: (
                res.data.data.ecServeClientWorkOrderVo.fileInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
              image: (
                res.data.data.ecServeClientWorkOrderVo.imageInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
            };

            if (this.esservedatiledata.type == 0) {
              this.esservedatiledata.type = "Unable to activate";
            } else if (this.esservedatiledata.type == 1) {
              this.esservedatiledata.type = "Offline";
            } else if (this.esservedatiledata.type == 2) {
              this.esservedatiledata.type = "APP";
            } else if (this.esservedatiledata.type == 3) {
              this.esservedatiledata.type = "Locate";
            } else if (this.esservedatiledata.type == 4) {
              this.esservedatiledata.type = "SIM card";
            } else if (this.esservedatiledata.type == 5) {
              this.esservedatiledata.type = "Package";
            } else if (this.esservedatiledata.type == 6) {
              this.esservedatiledata.type = "other";
            }

            if (this.esservedatiledata.usageScenario == 0) {
              this.esservedatiledata.usageScenario = "Vehicle";
            } else if (this.esservedatiledata.usageScenario == 1) {
              this.esservedatiledata.usageScenario = "Human";
            } else if (this.esservedatiledata.usageScenario == 2) {
              this.esservedatiledata.usageScenario = "Asset";
            } else if (this.esservedatiledata.usageScenario == 3) {
              this.esservedatiledata.usageScenario = "Other";
            }

            this.ESpreviewList = (
              res.data.data.ecServeClientWorkOrderVo.imageInfos || []
            ).map(({ url }) => url);
          }

          if (res.data.data.customerWorkOrderVo) {
            this.kfdatile = {
              ...res.data.data.customerWorkOrderVo,
              image: (res.data.data.customerWorkOrderVo.fileInfos || []).map(
                ({ path, url }) => ({
                  path,
                  url,
                })
              ),
            };
            this.KFpreviewList = (
              res.data.data.customerWorkOrderVo.fileInfos || []
            ).map(({ url }) => url);
          }

          if (res.data.data.developmentWorkOrderVo) {
            this.yanfadatile = {
              ...res.data.data.developmentWorkOrderVo,
              // attachment: (res.data.data.fileInfos || []).map(
              //   ({ path, url }) => ({
              //     path,
              //     url,
              //   })
              // ),
              // image: (res.data.data.imageInfos || []).map(({ path, url }) => ({
              //   path,
              //   url,
              // })),
            };
          }

          this.FAEdatile = {
            ...res.data.data.technicalSupportWorkOrderVo,
            attachment: (
              res.data.data.technicalSupportWorkOrderVo.fileInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
            image: (
              res.data.data.technicalSupportWorkOrderVo.imageInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
          };

          if (res.data.data.technicalSupportWorkOrderVo.process == 0) {
            this.steptype = 1;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 3) {
            this.steptype = 3;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 5) {
            this.steptype = 5;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 1) {
            this.steptype = 7;
          } else {
            this.steptype = 5;
          }

          // 预览图片列表
          this.FAEpreviewList = (
            res.data.data.technicalSupportWorkOrderVo?.imageInfos || []
          ).map(({ url }) => url);
          this.YFpreviewList = (
            res.data.data.developmentWorkOrderVo?.imageInfos || []
          ).map(({ url }) => url);
          this.solutionlinedata = suanfa(this.FAEdatile.solution, "#solve#");
          this.remakelinedate = suanfa(this.yanfadatile.remark);
        } else {
        }
      } catch (e) {
      } finally {
        this.showchangepsw = false;
      }
    },

    openChange() {
      this.showchangepsw = true;
    },
    reflesh() {
      this.loadArticles();
    },
    async changeorderhttp() {
      try {
        this.changeform.ownerType = this.form.ownerType;
        const res = await EditaeOrderUrl({
          ...this.changeform,
          attachment: (this.changeform.attachment || [])
            .map(({ path }) => path)
            .join(","),
          image: (this.changeform.image || [])
            .map(({ path }) => path)
            .join(","),
        });
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("xgcg"),
            type: "success",
          });
          this.loadArticles(1);
          this.changewindowsshow = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    async showdtimeline(row) {
      try {
        const res = await gettimeline({
          workOrderId: row.workOrderId,
        });
        if (res.data.code === 200) {
          this.timelinedata = res.data.data;
          this.timelineViewShow = true;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    async translate() {
      if (this.translating) return;
      this.translating = true;
      try {
        const res = await transformUrl({
          workOrderId: this.translateId,
          deptId: this.deptId,
        });
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("zhgc"),
            type: "success",
          });
          this.loadArticles(1);
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.translating = false;
        this.showkf = false;
        this.planVisable = false;
        this.showtranslate = false;
      }
    },
    giveFae(row, column, event) {
      this.setFaeManForm.workOrderId = row.workOrderId;
      this.showAssignFaeOperator = true;
    },
    async reset(row, column, event) {
      try {
        const res = await FaeResetOrderUrl({ workOrderId: row.workOrderId });
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("cgdych"),
            type: "success",
          });
          this.loadArticles(1);
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    async finish(row, column, event) {
      try {
        const res = await FaeFinish({ workOrderId: row.workOrderId });
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("cgdywc"),
            type: "success",
          });
          this.loadArticles(1);
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    async solveOrder() {
      try {
        this.solvform.ownerType = this.form.ownerType;
        const res = await FaeSolveOrderUrl(this.solvform);
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("gxcg"),
            type: "success",
          });
          (this.solvform = {
            workOrderId: "",
            remark: "",
            solution: "",
            stuckPoint: "",
            personLiable: "",
          }),
            this.loadArticles(1);
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.planVisable = false;
        this.showtranslate = false;
        this.showkf = false;
      }
    },
    makeAddfale(data) {
      this.AddVisible = data;
    },

    addorder() {
      this.AddVisible = true;
    },
    cansel() {
      this.delectview = false;
    },

    changeorder(row, column, event) {
      this.changeform = row;
      this.changeform.image = (row.imageInfos || []).map(({ path, url }) => ({
        name: path,
        path,
        url,
      }));
      this.changeform.attachment = (row.fileInfos || []).map(
        ({ path, url }) => ({
          name: path,
          path,
          url,
        })
      );
      this.changewindowsshow = true;
    },

    delect(row, column, event) {
      this.deletedvis = true;
      this.delectform.workOrderId = row.workOrderId;
      this.delectform.orderNo = row.orderNo;
    },

    refresh(row, column, event) {
      this.refreshvis = true;
      this.delectform.workOrderId = row.workOrderId;
    },

    async showdetaile(row, column, event) {
      this.solutionlinedata = [];
      this.remakelinedate = [];
      this.getdetaile(row.workOrderId);
      this.solvform = row;
      console.log(this.solvform);
      this.showkf = true;
      this.translateTO = 0;
      this.translateId = row.workOrderId;
    },

    async refreshOrder() {
      try {
        const res = await FaeRefreshOrder(this.delectform);
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("hscg"),
            type: "success",
          });

          this.loadArticles(1);
          this.refreshvis = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.refreshvis = false;
      }
    },

    async deletedOrder() {
      try {
        const res = await FaeDelect(this.delectform);
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("sccg"),
            type: "success",
          });

          this.loadArticles(1);
          this.deletedvis = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    exportexcel() {},

    async loadArticles(page = 1) {
      // 展示加载中 loading
      if (this.ifshoutian == true && this.shoutianjixing != null) {
        this.form.productModelType = this.shoutianjixing;
      }
      this.loading = true;
      try {
        const res = await SearchFaeOrderUrl(this.form);

        if (res.data.code === 200) {
          this.articles = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },

    /* 图片增删改查 */
    handleImgUploadSuccess(response, image) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.changeform.image = [
          ...this.changeform.image,
          { ...image, url, path },
        ];
      } else {
        this.changeform.image = [...this.changeform.image];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    handleImageRemove(file, image) {
      this.changeform.image = image;
    },
    /* 预览 */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /* 文件增删改查 */
    handleUploadSuccess(response, file) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.changeform.attachment = [
          ...this.changeform.attachment,
          { ...file, url, path },
        ];
      } else {
        this.changeform.attachment = [...this.changeform.attachment];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    handleRemove(file, attachment) {
      this.changeform.attachment = attachment;
    },
  },
};
</script>

<style scoped lang="less">
.header {
  float: right;
}
.filter-card {
  margin-bottom: 5px;
}

.list-table {
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  margin-bottom: 5px;
  width: 200px;
  margin-right: 10px;
}
.flexparents {
  margin-bottom: 5px;
}

.zdhh {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}
.kesuitem {
  margin: 9px;
}
.chongqi {
  background-color: green;
}
.timelineparents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.title {
  color: #000000;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
}
.dialog-footer {
  margin: 5px;
}

.destitle {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

/deep/.el-descriptions-item__label {
  font-weight: bold !important;
  color: rgb(0, 0, 0);
}

.desbasebody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
  background-color: #ecf4fd;
  /deep/.el-descriptions__table {
    background-color: #ecf4fd;
  }
}
.desbody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
}

/deep/.el-table .complate-row {
  background: #ffffff;
}
/deep/.el-table .white-row {
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
}
.gap {
  margin-top: 20px;
  margin-bottom: 30px;
}
.titleTop {
  display: flex;
  justify-content: space-between;
}
</style>
